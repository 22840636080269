
import { defineComponent } from 'vue'
import ContactsSidebar from '@/components/pages/contacts/ContactsSidebar.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    ContactsSidebar,
    TmButton,
  },
})
