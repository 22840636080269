<template>
  <div class="page-content-sidebar-hr">
    <hr>
  </div>
</template>

<style lang="scss" scoped>
.page-content-sidebar-hr {
  padding: 7px 16px;

  hr {
    width: 100%;
  }
}
</style>
