import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "contact-sidebar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_content_sidebar_list = _resolveComponent("page-content-sidebar-list")!
  const _component_page_content_sidebar_hr = _resolveComponent("page-content-sidebar-hr")!
  const _component_page_content_sidebar_list_title = _resolveComponent("page-content-sidebar-list-title")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_page_content_sidebar_list, { items: _ctx.contactsSidebarRoutes }, null, 8, ["items"]),
    _createVNode(_component_page_content_sidebar_hr),
    _createVNode(_component_page_content_sidebar_list_title, {
      title: "Pinned",
      "show-add-button": "",
      onAddBtnClick: _ctx.openPinModal
    }, null, 8, ["onAddBtnClick"]),
    _createVNode(_component_page_content_sidebar_list, { items: _ctx.contactsSidebarPinRoutes }, null, 8, ["items"])
  ]))
}