
import { defineComponent } from 'vue'
import PageContentSidebarList from '@/components/layout/pageContent/sidebar/PageContentSidebarList.vue'
import PageContentSidebarHr from '@/components/layout/pageContent/sidebar/PageContentSidebarHr.vue'
import PageContentSidebarListTitle from '@/components/layout/pageContent/sidebar/sidebarItem/PageContentSidebarListTitle.vue'
import { contactsSidebarRoutes, contactsSidebarPinRoutes } from '@/router/sidebarRoutes/contactsRoutes'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    PageContentSidebarList,
    PageContentSidebarHr,
    PageContentSidebarListTitle,
  },
  setup() {
    const { openModal } = useModals()
    const openPinModal = () => {
      openModal('pinModal')
    }

    return {
      contactsSidebarRoutes,
      contactsSidebarPinRoutes,
      openPinModal,
    }
  },
})
